import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';

import IndexLayout from '../layouts';
import Page from '../components/shared/page';
import Container from '../components/shared/container';
import PageHero from '../components/shared/page-hero';
import BlogTags from '../components/shared/blog-tags';
import { breakpoints, colors } from '../styles/variables';

const BlogMeta = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  svg {
    width: 14px;
    height: 14px;
    fill: #fff;
    opacity: 0.4;
    margin-right: 10px;
  }
`;

const BlogReadTime = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3em;
`;

const BlogDate = styled.time`
  display: flex;
  align-items: center;
  margin-right: 3em;
`;

const BlogPostContent = styled.div`
  width: 80%;
  color: ${colors.gray.copy};
  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        author?: {
          name: string;
          url: string;
        };
      };
    };
    markdownRemark: {
      html: string;
      excerpt: string;
      timeToRead: string;
      frontmatter: {
        title: string;
        date: string;
        tags: string;
      };
    };
  };
}

const PageTemplate: React.FunctionComponent<PageTemplateProps> = ({
  data,
}) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <IndexLayout title={frontmatter.title}>
      <Helmet
        meta={[
          {
            name: 'Description',
            content: data.markdownRemark.excerpt,
          },
          {
            name: 'Keywords',
            content: frontmatter.tags,
          },
        ]}
      />
      <Page>
        <PageHero>
          <h1>{frontmatter.title}</h1>
          <BlogMeta>
            <BlogDate>
              <svg viewBox="0 0 24 24">
                <path d="M17 1c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-12 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm13 5v10h-16v-10h16zm2-6h-2v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-8v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-2v18h20v-18zm4 3v19h-22v-2h20v-17h2zm-17 7h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z" />
              </svg>
              Posted on {frontmatter.date}
            </BlogDate>
            <BlogReadTime>
              <svg viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 12v-6h-2v8h7v-2h-5z" />
              </svg>
              Read time {data.markdownRemark.timeToRead} minute
              {data.markdownRemark.timeToRead === '1' ? '' : 's'}
            </BlogReadTime>
            <BlogTags tags={frontmatter.tags} />
          </BlogMeta>
        </PageHero>
        <Container className="medium">
          <BlogPostContent
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      timeToRead
      frontmatter {
        title
        date
        tags
      }
    }
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { opacify } from 'polished';

const StyledBlogTags = styled.div`
  display: flex;
  align-items: center;
`;

const BlogTagList = styled.ul`
  list-style-type: none;
  margin: 0 0 0 1em;
  padding: 0;
  display: flex;
`;

const BlogTag = styled.li`
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  margin-right: 8px;
  padding: 1px 8px;
  display: flex;
  align-items: center;
  border: 1px dashed ${opacify(-0.8, '#fff')};
  border-radius: 4px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const BlogTags: React.FunctionComponent<{ tags: string }> = ({ tags }) => (
  <>
    {tags.includes(',') ? (
      <StyledBlogTags>
        <svg viewBox="0 0 24 24">
          <path d="M12.049 4l9.122 9.112-6.189 6.188-8.982-9.106v-6.194h6.049zm.827-2h-8.876v9.015l10.973 11.124 9.027-9.028-11.124-11.111zm-2.315 6.561c-.586.586-1.535.586-2.121 0s-.586-1.535 0-2.121c.586-.586 1.535-.586 2.121 0 .585.585.585 1.535 0 2.121zm1.042 13.003l-1.369 1.436-10.234-10.257v-7.743h2v6.891l9.603 9.673z" />
        </svg>
        Tags
        <BlogTagList>
          {tags.split(',').map((tag, index) => (
            <BlogTag key={index} title={tag}>
              {tag}
            </BlogTag>
          ))}
        </BlogTagList>
      </StyledBlogTags>
    ) : null}
  </>
);

export default BlogTags;
